.mfa-button {
	border: none;
    background: $main-color;
    color: #fff;
   	padding: 5px 20px 8px;
   	border-radius: 4px;
   	display: inline-block;
}

.mfa-cons {
	min-width: 120px;
}