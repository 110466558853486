/*.sp-thumbnail-container {
	border-bottom: 1px solid rgba(255,255,255,0.2);
}

.sp-thumbnail-container.sp-selected-thumbnail {
	&::after {
		display: none;
	}
}

.sp-thumbnails.sp-grab {
	background: #000;
}

.sp-thumbnails.sp-grabbing {
	background: #000;
}

.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
	margin-left: 0;
}

.sp-thumbnail .sp-thumbnail-text {
	color: #555;
}

.sp-selected-thumbnail .sp-thumbnail .sp-thumbnail-text {
	color: #fff;
}

.sp-thumbnails-container {
	background: #000;
}*/
.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail {
	background: #fbfbfb;
}

.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
	border: none;
}

.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
	//border: none;
	right: 0 !important;
	left: auto;
	border-left: 4px solid $main-color;
}

.sp-left-thumbnails .sp-thumbnail-container, .sp-right-thumbnails .sp-thumbnail-container {
	background: $secondary-background-color;
	margin: 0;
	left: 0;
	padding: 5px;
	border-bottom: 1px solid rgba(#000, 0.1);

	.sp-thumbnail {
		.date {
			display: block;
			color: #051F45;
			font-size: 13px;
		}

		.desc {
			color: #4E4E4E;
			font-weight: 300;

			&:hover {
				color: $main-color;
			}
		}
	}
}

.sp-right-thumbnails.sp-has-pointer .sp-thumbnail {
	left: 15px;
	right: 15px;
}



