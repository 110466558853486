@font-face {
    font-family: DINPro;
    src: url(/fonts/DINPro/DINPro-Regular.otf);
    font-weight: 300;
}

@font-face {
    font-family: DINPro;
    src: url(/fonts/DINPro/DINPro-Light.otf);
    font-weight: 100;
}

@font-face {
    font-family: DINPro;
    src: url(/fonts/DINPro/DINPro-Bold.otf);
    font-weight: bold;
}

@font-face {
    font-family: DINPro;
    src: url(/fonts/DINPro/DINPro-Medium.otf);
    font-weight: 500;
}

@font-face {
    font-family: RobotoSlab;
    src: url(/fonts/Roboto_Slab/RobotoSlab-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: RobotoSlab;
    src: url(/fonts/Roboto_Slab/RobotoSlab-Regular.ttf);
    font-weight: normal;
}

@font-face {
    font-family: RobotoSlab;
    src: url(/fonts/Roboto_Slab/RobotoSlab-Light.ttf);
    font-weight: lighter;
}