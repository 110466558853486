@font-face {
  font-family: DINPro;
  src: url(/fonts/DINPro/DINPro-Regular.otf);
  font-weight: 300;
}

@font-face {
  font-family: DINPro;
  src: url(/fonts/DINPro/DINPro-Light.otf);
  font-weight: 100;
}

@font-face {
  font-family: DINPro;
  src: url(/fonts/DINPro/DINPro-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: DINPro;
  src: url(/fonts/DINPro/DINPro-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: RobotoSlab;
  src: url(/fonts/Roboto_Slab/RobotoSlab-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: RobotoSlab;
  src: url(/fonts/Roboto_Slab/RobotoSlab-Regular.ttf);
  font-weight: normal;
}

@font-face {
  font-family: RobotoSlab;
  src: url(/fonts/Roboto_Slab/RobotoSlab-Light.ttf);
  font-weight: lighter;
}

.m-t-default {
  margin-top: 30px;
}

.m-b-default {
  margin-bottom: 30px;
}

.m-t-default-bigger {
  margin-top: 50px;
}

.m-b-default-bigger {
  margin-bottom: 50px;
}

/*
// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
*/
.mfa-form input {
  width: 100%;
  height: 50px;
  padding: 0 10px;
  border-radius: 4px;
  outline: none;
  border: none;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  transition: all .4s ease;
}

.mfa-form input:focus {
  border: 1px solid #144b9d;
  background: #fff;
}

.mfa-form textarea {
  width: 100%;
  padding: 10px;
  outline: none;
  border-radius: 4px;
  color: #144b9d;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  transition: all .4s ease;
}

.mfa-form textarea:focus {
  border: 1px solid #144b9d;
  background: #fff;
}

.mfa-button {
  border: none;
  background: #144b9d;
  color: #fff;
  padding: 5px 20px 8px;
  border-radius: 4px;
  display: inline-block;
}

.mfa-cons {
  min-width: 120px;
}

/*.sp-thumbnail-container {
	border-bottom: 1px solid rgba(255,255,255,0.2);
}

.sp-thumbnail-container.sp-selected-thumbnail {
	&::after {
		display: none;
	}
}

.sp-thumbnails.sp-grab {
	background: #000;
}

.sp-thumbnails.sp-grabbing {
	background: #000;
}

.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
	margin-left: 0;
}

.sp-thumbnail .sp-thumbnail-text {
	color: #555;
}

.sp-selected-thumbnail .sp-thumbnail .sp-thumbnail-text {
	color: #fff;
}

.sp-thumbnails-container {
	background: #000;
}*/
.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail {
  background: #fbfbfb;
}

.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
  border: none;
}

.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
  right: 0 !important;
  left: auto;
  border-left: 4px solid #144b9d;
}

.sp-left-thumbnails .sp-thumbnail-container, .sp-right-thumbnails .sp-thumbnail-container {
  background: #f5f5f5;
  margin: 0;
  left: 0;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sp-left-thumbnails .sp-thumbnail-container .sp-thumbnail .date, .sp-right-thumbnails .sp-thumbnail-container .sp-thumbnail .date {
  display: block;
  color: #051F45;
  font-size: 13px;
}

.sp-left-thumbnails .sp-thumbnail-container .sp-thumbnail .desc, .sp-right-thumbnails .sp-thumbnail-container .sp-thumbnail .desc {
  color: #4E4E4E;
  font-weight: 300;
}

.sp-left-thumbnails .sp-thumbnail-container .sp-thumbnail .desc:hover, .sp-right-thumbnails .sp-thumbnail-container .sp-thumbnail .desc:hover {
  color: #144b9d;
}

.sp-right-thumbnails.sp-has-pointer .sp-thumbnail {
  left: 15px;
  right: 15px;
}

.accordion {
  position: relative;
  background-color: #fff;
  display: inline-block;
  width: 100%;
  border-top: 1px solid #f1f4f3;
  border-bottom: 1px solid #f1f4f3;
  font-weight: 700;
  color: #74777b;
  vertical-align: middle;
}

.accordion .fa {
  position: relative;
  float: right;
}

.accordion h4 {
  position: relative;
  top: 0.8em;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
}

.accordion a {
  position: relative;
  display: block;
  color: #74777b;
  padding: 1em 1em 2.5em 1em;
  text-decoration: none;
}

.accordion a:hover {
  text-decoration: none;
  color: #2cc185;
  background-color: #e7ecea;
  transition: 0.3s;
}

.accordion-desc {
  background-color: #f1f4f3;
  color: #74777b;
  z-index: 2;
  padding: 20px 15px;
}

body {
  font-family: DINPro, sans-serif;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
}

a {
  text-decoration: none !important;
  /*&:hover {
		color: rgba(#fff, 0.8);
	}

	&:focus {
		outline: none;
	}*/
}

.sp-left-thumbnails .sp-thumbnail-container .sp-thumbnail .desc, .sp-right-thumbnails .sp-thumbnail-container .sp-thumbnail .desc {
  height: 62px;
  overflow: hidden;
  display: block;
}

.announcement {
  background: #f5f5f5;
  padding: 15px;
  border-radius: 4px;
}

.announcement p {
  color: #4E4E4E;
}

.announcement .additional-info {
  float: right;
  background: transparent;
  border: 1px solid #144b9d;
  padding: 0 25px;
  height: 40px;
  line-height: 37px;
  color: #144b9d;
  display: inline-block;
  border-radius: 4px;
}

.announcement .additional-info:hover {
  color: rgba(20, 75, 157, 0.8);
  border-color: rgba(20, 75, 157, 0.8);
}

.news-show table {
  width: 100% !important;
}

.news-show table tr:first-child {
  background: rgba(0, 0, 0, 0.1);
}

.news-show table td {
  padding: 10px 15px 10px 15px;
  font-size: 16px;
  font-weight: 300;
}

.news-show table p {
  text-align: left !important;
  margin: 0 !important;
}

.b-r-4 {
  border-radius: 4px;
}

.news-show p {
  font-weight: 300;
  text-align: justify;
  font-size: 16px;
}

.news-show img {
  margin: 0 15px 14px 0;
}

.modal.in .modal-dialog {
  width: 1000px;
}

.websites .website {
  position: relative;
  padding-bottom: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.websites .website .title {
  color: #144b9d;
}

.websites .website .title:hover {
  color: rgba(20, 75, 157, 0.8);
}

.websites .website .link {
  display: block;
  font-style: italic;
  margin-bottom: 20px;
  color: #4e4e4e;
}

.websites .website .link:hover {
  color: #4e4e4e;
}

.modal-body {
  background-color: #000;
}

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
  color: #144b9d;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background-color: #144b9d;
  border-color: #144b9d;
}

.pagination > li > a, .pagination > li > span {
  color: #144b9d;
}

.countdown {
  text-align: center;
  background: #f5f5f5;
  padding: 30px 0;
  color: #144b9d;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.countdown .part {
  display: inline-block;
  margin-right: 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  position: relative;
  font-size: 11px;
}

.countdown .part::after {
  content: '';
  position: absolute;
  right: -11px;
  top: 6px;
  height: 24px;
  width: 1px;
  background: #144b9d;
}

.countdown .part:nth-last-child(2) {
  margin-right: 0;
}

.countdown .part:nth-last-child(2)::after {
  display: none;
}

.countdown .part .number {
  display: block;
  font-size: 24px;
  font-weight: bold;
}

.asian-games {
  position: relative;
  overflow: hidden;
  min-height: 180px;
}

.asian-games .cover {
  width: 100%;
}

.asian-games .main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 435px;
  padding-top: 25px;
  color: rgba(0, 0, 0, 0.7);
}

.asian-games .main .title {
  font-size: 24px;
  font-weight: 200;
  margin: 10px 0 0 0;
}

.asian-games .main .countdown-wrapper .desc {
  font-size: 14px;
  width: 340px;
  font-weight: 500;
  float: left;
  padding-right: 40px;
  position: relative;
  margin: 0;
  margin-top: 10px;
}

.asian-games .main .countdown-wrapper .desc::after {
  position: absolute;
  content: '';
  height: 50px;
  width: 1px;
  background: #000;
  right: 0;
  top: -7px;
}

.asian-games .main .countdown-wrapper .countdown {
  float: left;
  margin-left: 50px;
}

.asian-games .main .countdown-wrapper .countdown .part {
  float: left;
  text-align: center;
  margin-right: 15px;
  text-transform: uppercase;
  font-size: 13px;
}

.asian-games .main .countdown-wrapper .countdown .part .number {
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.mfa-container {
  max-width: 1280px;
  padding-left: 60px;
  padding-right: 60px;
  margin: auto;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

.main-title {
  border-left: 3px solid #144b9d;
  padding-left: 15px;
  text-transform: uppercase;
  color: #144b9d;
}

.bold {
  font-weight: bold;
}

.new-events {
  background: #f5f5f5;
  padding: 15px;
  border-radius: 4px;
}

.new-events .date {
  font-weight: bold;
  color: #4e4e4e;
}

.new-events .desc a {
  color: #4e4e4e;
  font-weight: 300;
}

.new-events .desc a:hover {
  color: #144b9d;
}

.news .item {
  margin-bottom: 30px;
  position: relative;
  text-align: justify;
}

.news .item h5 {
  margin-bottom: 15px;
  color: #144b9d;
  font-size: 15px;
}

.news .item .img-wrapper {
  float: left;
  width: 200px;
  height: 150px;
  overflow: hidden;
  margin-right: 20px;
}

.news .item .img-wrapper img {
  width: 100%;
}

.news .item .read-more {
  position: absolute;
  bottom: 0;
  left: 220px;
  border-radius: 4px;
  padding: 7px 20px 8px;
}

.breadcrumbs ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.breadcrumbs ul li {
  position: relative;
  float: left;
  margin-right: 15px;
  color: #4e4e4e;
  font-weight: 300;
}

.breadcrumbs ul li a {
  font-weight: bold;
  color: #144b9d;
}

.breadcrumbs ul li::after {
  content: '/';
  position: absolute;
  right: -10px;
  top: 0;
}

.breadcrumbs ul li:nth-last-child(2)::after {
  display: none;
}

.derwayys-wrapper ul.derwayys {
  margin: 0;
  padding: 0;
  list-style-type: none;
  background: #f5f5f5;
  border-radius: 4px;
}

.derwayys-wrapper ul.derwayys li {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.derwayys-wrapper ul.derwayys li:last-child {
  border: none;
}

.derwayys-wrapper ul.derwayys li a {
  color: #4e4e4e;
  font-weight: 300;
}

.derwayys-wrapper ul.derwayys li a:hover {
  color: #144b9d;
}

.something {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
}

.something img {
  transform: scale(1.05) !important;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

.something:hover img {
  transform: scale(1.1) !important;
}

.something .text-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.something .text-wrapper .text {
  width: 240px;
  display: block;
}

.something a {
  color: #4e4e4e;
}

.something a:hover {
  color: #144b9d;
}

.contact-button {
  background: transparent;
  border: 1px solid #fff;
  padding: 0 25px;
  height: 40px;
  line-height: 37px;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
}

.contact-button:hover {
  color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.8);
}

.contacts-send-button {
  background: #144b9d;
  color: #fff;
  padding: 7px 25px;
  border: none;
  border-radius: 4px;
}

.mfa-button {
  background: #144b9d;
  color: #fff;
  padding: 7px 25px;
  border: none;
  border-radius: 4px;
}

.mfa-button:hover {
  color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.8);
}

.contacts .contact-info {
  border-radius: 4px;
  padding: 15px;
  background: #f5f5f5;
}

.booklet {
  position: relative;
}

.booklet img {
  float: left;
  margin-right: 30px;
}

.booklet .button {
  position: absolute;
  bottom: 1px;
}

.asiada .desc p {
  text-align: justify;
  font-weight: 300;
}

header {
  min-height: 126px;
  background-position: 50% 0;
  background-size: cover;
  position: relative;
}

header.home {
  min-height: 400px;
  background-image: url(/img/header_background.jpg);
}

header.home nav {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

header.not-home nav {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
}

header .main .asiada-2017 {
  float: right;
  text-align: center;
  margin-right: 175px;
  color: #144b9d;
}

header .main .asiada-2017 img {
  width: 100px;
  margin-bottom: 15px;
  margin-top: 3px;
}

header #top-header {
  background: rgba(20, 75, 157, 0.9);
  padding-top: 15px;
  height: 70px;
}

header #top-header .logo {
  float: left;
  width: 43px;
  margin-right: 15px;
}

header #top-header .logo-text {
  float: left;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 11px;
}

header #top-header .logo-text a {
  color: #fff;
}

header #top-header ul.languages {
  float: right;
  padding: 0;
  margin: 0;
  list-style-type: none;
  color: #fff;
  padding-top: 9px;
}

header #top-header ul.languages li {
  float: left;
  margin-right: 10px;
  text-transform: uppercase;
}

header #top-header ul.languages li a {
  color: #fff;
  font-weight: bold;
}

header #top-header ul.languages li a:hover, header #top-header ul.languages li a.active {
  color: rgba(255, 255, 255, 0.8);
}

header .main {
  padding: 45px 60px;
}

header .main .title {
  float: left;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  font-family: RobotoSlab, sans-serif;
}

header .main .logo-info {
  float: right;
  color: #fff;
  font-size: 20px;
  text-align: center;
}

header .main .logo-info img {
  margin-bottom: 15px;
}

header nav {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

header nav.fixed {
  bottom: auto;
  top: 0;
  position: fixed;
  z-index: 1000;
}

header nav ul.navigation {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: table;
  margin: auto;
  height: 56px;
}

header nav ul.navigation > li {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  height: 56px;
  position: relative;
}

header nav ul.navigation > li a {
  padding: 10px;
  font-size: 14px;
  color: #4E4E4E;
  display: block;
  width: 100%;
  height: 56px;
  text-decoration: none;
  display: table-cell;
  vertical-align: middle;
}

header nav ul.navigation > li a:hover {
  color: #144b9d;
}

header nav ul.navigation > li::after {
  content: '';
  position: absolute;
  height: 25px;
  width: 1px;
  background: #D2D2D2;
  right: 0;
  top: 16px;
}

header nav ul.navigation > li:nth-last-child(2)::after {
  display: none;
}

.widgets-wrapper {
  background-image: url(/img/parallax_image.jpg);
  color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
}

.widgets-wrapper .widgets {
  padding-left: 60px;
  padding-right: 60px;
}

.widgets-wrapper .widget {
  text-align: center;
  width: 270px;
  margin: auto;
}

.widgets-wrapper .widget img {
  height: 55px;
  margin-bottom: 30px;
  transform: scale(1);
  transition: all .3s ease;
}

.widgets-wrapper .widget a {
  color: #fff;
}

.widgets-wrapper .widget a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.widgets-wrapper .widget .title {
  font-weight: bold;
}

.widgets-wrapper .widget .desc {
  font-weight: 300;
}

.widgets-wrapper .widget:hover img {
  transform: scale(0.9);
}

.institute {
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer-wrapper {
  background: #144b9d;
}

footer {
  padding-top: 50px;
  padding-bottom: 50px;
  color: #fff;
}

.footer-links-title {
  text-transform: uppercase;
  font-weight: bold;
}

ul.footer-links {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul.footer-links li {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 6px;
}

ul.footer-links li a {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
}

ul.footer-links li a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.footer-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  font-weight: 300;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 14px;
}

.p-t-0 {
  padding-top: 0px;
}

.p-r-0 {
  padding-right: 0px;
}

.p-l-0 {
  padding-left: 0px;
}

.p-b-0 {
  padding-bottom: 0px;
}

.padding-0 {
  padding: 0px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.padding-5 {
  padding: 5px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.padding-10 {
  padding: 10px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.padding-15 {
  padding: 15px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.padding-20 {
  padding: 20px;
}

.p-t-25 {
  padding-top: 25px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-b-25 {
  padding-bottom: 25px;
}

.padding-25 {
  padding: 25px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.padding-30 {
  padding: 30px;
}

.p-t-35 {
  padding-top: 35px;
}

.p-r-35 {
  padding-right: 35px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-b-35 {
  padding-bottom: 35px;
}

.padding-35 {
  padding: 35px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.padding-40 {
  padding: 40px;
}

.p-t-45 {
  padding-top: 45px;
}

.p-r-45 {
  padding-right: 45px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-b-45 {
  padding-bottom: 45px;
}

.padding-45 {
  padding: 45px;
}

.p-t-50 {
  padding-top: 50px;
}

.p-r-50 {
  padding-right: 50px;
}

.p-l-50 {
  padding-left: 50px;
}

.p-b-50 {
  padding-bottom: 50px;
}

.padding-50 {
  padding: 50px;
}

.p-t-55 {
  padding-top: 55px;
}

.p-r-55 {
  padding-right: 55px;
}

.p-l-55 {
  padding-left: 55px;
}

.p-b-55 {
  padding-bottom: 55px;
}

.padding-55 {
  padding: 55px;
}

.p-t-60 {
  padding-top: 60px;
}

.p-r-60 {
  padding-right: 60px;
}

.p-l-60 {
  padding-left: 60px;
}

.p-b-60 {
  padding-bottom: 60px;
}

.padding-60 {
  padding: 60px;
}

.p-t-65 {
  padding-top: 65px;
}

.p-r-65 {
  padding-right: 65px;
}

.p-l-65 {
  padding-left: 65px;
}

.p-b-65 {
  padding-bottom: 65px;
}

.padding-65 {
  padding: 65px;
}

.p-t-70 {
  padding-top: 70px;
}

.p-r-70 {
  padding-right: 70px;
}

.p-l-70 {
  padding-left: 70px;
}

.p-b-70 {
  padding-bottom: 70px;
}

.padding-70 {
  padding: 70px;
}

.p-t-75 {
  padding-top: 75px;
}

.p-r-75 {
  padding-right: 75px;
}

.p-l-75 {
  padding-left: 75px;
}

.p-b-75 {
  padding-bottom: 75px;
}

.padding-75 {
  padding: 75px;
}

.p-t-80 {
  padding-top: 80px;
}

.p-r-80 {
  padding-right: 80px;
}

.p-l-80 {
  padding-left: 80px;
}

.p-b-80 {
  padding-bottom: 80px;
}

.padding-80 {
  padding: 80px;
}

.p-t-85 {
  padding-top: 85px;
}

.p-r-85 {
  padding-right: 85px;
}

.p-l-85 {
  padding-left: 85px;
}

.p-b-85 {
  padding-bottom: 85px;
}

.padding-85 {
  padding: 85px;
}

.p-t-90 {
  padding-top: 90px;
}

.p-r-90 {
  padding-right: 90px;
}

.p-l-90 {
  padding-left: 90px;
}

.p-b-90 {
  padding-bottom: 90px;
}

.padding-90 {
  padding: 90px;
}

.p-t-95 {
  padding-top: 95px;
}

.p-r-95 {
  padding-right: 95px;
}

.p-l-95 {
  padding-left: 95px;
}

.p-b-95 {
  padding-bottom: 95px;
}

.padding-95 {
  padding: 95px;
}

.p-t-100 {
  padding-top: 100px;
}

.p-r-100 {
  padding-right: 100px;
}

.p-l-100 {
  padding-left: 100px;
}

.p-b-100 {
  padding-bottom: 100px;
}

.padding-100 {
  padding: 100px;
}

.p-t-105 {
  padding-top: 105px;
}

.p-r-105 {
  padding-right: 105px;
}

.p-l-105 {
  padding-left: 105px;
}

.p-b-105 {
  padding-bottom: 105px;
}

.padding-105 {
  padding: 105px;
}

.p-t-110 {
  padding-top: 110px;
}

.p-r-110 {
  padding-right: 110px;
}

.p-l-110 {
  padding-left: 110px;
}

.p-b-110 {
  padding-bottom: 110px;
}

.padding-110 {
  padding: 110px;
}

.p-t-115 {
  padding-top: 115px;
}

.p-r-115 {
  padding-right: 115px;
}

.p-l-115 {
  padding-left: 115px;
}

.p-b-115 {
  padding-bottom: 115px;
}

.padding-115 {
  padding: 115px;
}

.p-t-120 {
  padding-top: 120px;
}

.p-r-120 {
  padding-right: 120px;
}

.p-l-120 {
  padding-left: 120px;
}

.p-b-120 {
  padding-bottom: 120px;
}

.padding-120 {
  padding: 120px;
}

.p-t-125 {
  padding-top: 125px;
}

.p-r-125 {
  padding-right: 125px;
}

.p-l-125 {
  padding-left: 125px;
}

.p-b-125 {
  padding-bottom: 125px;
}

.padding-125 {
  padding: 125px;
}

.m-t-0 {
  margin-top: 0px;
}

.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.m-b-0 {
  margin-bottom: 0px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-l-35 {
  margin-left: 35px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-l-45 {
  margin-left: 45px;
}

.m-b-45 {
  margin-bottom: 45px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-r-50 {
  margin-right: 50px;
}

.m-l-50 {
  margin-left: 50px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-t-55 {
  margin-top: 55px;
}

.m-r-55 {
  margin-right: 55px;
}

.m-l-55 {
  margin-left: 55px;
}

.m-b-55 {
  margin-bottom: 55px;
}

.m-t-60 {
  margin-top: 60px;
}

.m-r-60 {
  margin-right: 60px;
}

.m-l-60 {
  margin-left: 60px;
}

.m-b-60 {
  margin-bottom: 60px;
}

.m-t-65 {
  margin-top: 65px;
}

.m-r-65 {
  margin-right: 65px;
}

.m-l-65 {
  margin-left: 65px;
}

.m-b-65 {
  margin-bottom: 65px;
}

.m-t-70 {
  margin-top: 70px;
}

.m-r-70 {
  margin-right: 70px;
}

.m-l-70 {
  margin-left: 70px;
}

.m-b-70 {
  margin-bottom: 70px;
}

.m-t-75 {
  margin-top: 75px;
}

.m-r-75 {
  margin-right: 75px;
}

.m-l-75 {
  margin-left: 75px;
}

.m-b-75 {
  margin-bottom: 75px;
}

.m-t-80 {
  margin-top: 80px;
}

.m-r-80 {
  margin-right: 80px;
}

.m-l-80 {
  margin-left: 80px;
}

.m-b-80 {
  margin-bottom: 80px;
}

.m-t-85 {
  margin-top: 85px;
}

.m-r-85 {
  margin-right: 85px;
}

.m-l-85 {
  margin-left: 85px;
}

.m-b-85 {
  margin-bottom: 85px;
}

.m-t-90 {
  margin-top: 90px;
}

.m-r-90 {
  margin-right: 90px;
}

.m-l-90 {
  margin-left: 90px;
}

.m-b-90 {
  margin-bottom: 90px;
}

.m-t-95 {
  margin-top: 95px;
}

.m-r-95 {
  margin-right: 95px;
}

.m-l-95 {
  margin-left: 95px;
}

.m-b-95 {
  margin-bottom: 95px;
}

.m-t-100 {
  margin-top: 100px;
}

.m-r-100 {
  margin-right: 100px;
}

.m-l-100 {
  margin-left: 100px;
}

.m-b-100 {
  margin-bottom: 100px;
}

.m-t-105 {
  margin-top: 105px;
}

.m-r-105 {
  margin-right: 105px;
}

.m-l-105 {
  margin-left: 105px;
}

.m-b-105 {
  margin-bottom: 105px;
}

.m-t-110 {
  margin-top: 110px;
}

.m-r-110 {
  margin-right: 110px;
}

.m-l-110 {
  margin-left: 110px;
}

.m-b-110 {
  margin-bottom: 110px;
}

.m-t-115 {
  margin-top: 115px;
}

.m-r-115 {
  margin-right: 115px;
}

.m-l-115 {
  margin-left: 115px;
}

.m-b-115 {
  margin-bottom: 115px;
}

.m-t-120 {
  margin-top: 120px;
}

.m-r-120 {
  margin-right: 120px;
}

.m-l-120 {
  margin-left: 120px;
}

.m-b-120 {
  margin-bottom: 120px;
}

.m-t-125 {
  margin-top: 125px;
}

.m-r-125 {
  margin-right: 125px;
}

.m-l-125 {
  margin-left: 125px;
}

.m-b-125 {
  margin-bottom: 125px;
}

.f-s-16 {
  font-size: 16px;
}

.f-s-17 {
  font-size: 17px;
}

.f-s-18 {
  font-size: 18px;
}

.f-s-19 {
  font-size: 19px;
}

.f-s-20 {
  font-size: 20px;
}

.f-s-21 {
  font-size: 21px;
}

.f-s-22 {
  font-size: 22px;
}

.f-s-23 {
  font-size: 23px;
}

.f-s-24 {
  font-size: 24px;
}

.f-s-25 {
  font-size: 25px;
}

.f-s-26 {
  font-size: 26px;
}

.f-s-27 {
  font-size: 27px;
}

.f-s-28 {
  font-size: 28px;
}

.f-s-29 {
  font-size: 29px;
}

.f-s-30 {
  font-size: 30px;
}

.f-s-31 {
  font-size: 31px;
}

.f-s-32 {
  font-size: 32px;
}

.f-s-33 {
  font-size: 33px;
}

.f-s-34 {
  font-size: 34px;
}

.f-s-35 {
  font-size: 35px;
}

.f-s-36 {
  font-size: 36px;
}

.f-s-37 {
  font-size: 37px;
}

.f-s-38 {
  font-size: 38px;
}

.f-s-39 {
  font-size: 39px;
}

.f-s-40 {
  font-size: 40px;
}
