.mfa-form {
	input {
		width: 100%;
		height: 50px;
		padding: 0 10px;
		border-radius: 4px;

		outline: none;

		
		border: none;

		border: 1px solid $secondary-background-color;
		background: $secondary-background-color;

		transition: all .4s ease;

		&:focus {
			border: 1px solid $main-color;
			background: #fff;

		}
	}

	textarea {
		width: 100%;
		padding: 10px;
		outline: none;
		border-radius: 4px;
		
		color: $main-color;

		border: 1px solid $secondary-background-color;
		background: $secondary-background-color;

		transition: all .4s ease;

		&:focus {
			border: 1px solid $main-color;
			background: #fff;

		}
	}
}
