.accordion {
  position: relative;
  background-color: #fff;
  display: inline-block;
  width: 100%;
  border-top: 1px solid #f1f4f3;
  border-bottom: 1px solid #f1f4f3;
  font-weight: 700;
  color: #74777b;
  vertical-align: middle;
}
 
.accordion .fa {
  position: relative;
  float: right;
}
 
.accordion h4 {
  position: relative;
  top: 0.8em;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
}
 
.accordion a {
  position: relative;
  display: block;
  color: #74777b;
  padding: 1em 1em 2.5em 1em;
  text-decoration: none;
}
 
.accordion a:hover {
  text-decoration: none;
  color: #2cc185;
  background-color: #e7ecea;
  transition: 0.3s;
}
 
.accordion-desc {
  background-color: #f1f4f3;
  color: #74777b;
  z-index: 2;
  padding: 20px 15px;
}
