body {
	font-family: $font-family;

	font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
}

a {
	text-decoration: none !important;

	/*&:hover {
		color: rgba(#fff, 0.8);
	}

	&:focus {
		outline: none;
	}*/
}

.sp-left-thumbnails .sp-thumbnail-container .sp-thumbnail .desc, .sp-right-thumbnails .sp-thumbnail-container .sp-thumbnail .desc {
	height: 62px; 
    overflow: hidden; 
    display: block; 
}

.announcement {
	background: #f5f5f5;
    padding: 15px;
    border-radius: 4px;

    p {
    	color: #4E4E4E;
    }

    .additional-info {
    	// padding: 12px 25px;
    	// background: transparent;
    	// color: $main-color;
    	// border: 2px solid $main-color;
    	// font-weight: bold;
    	// border-radius: 4px;
    	float: right;

    	background: transparent;
	    border: 1px solid $main-color;
	    padding: 0 25px;
	    height: 40px;
	    line-height: 37px;
	    color: $main-color;
	    display: inline-block;
	    border-radius: 4px;

	    &:hover {
	    	color: rgba($main-color, 0.8);
	    	border-color: rgba($main-color, 0.8);
	    }
    }
}

.news-show {
	table {
		width: 100% !important;

		tr:first-child {
			background: rgba(0, 0, 0, 0.1);
		}

		td {
			padding: 10px 15px 10px 15px;
			font-size: 16px;
			font-weight: 300;
		}

		p {
			text-align: left !important;
			margin: 0 !important;
		}
	}
}

.b-r-4 {
	border-radius: 4px;
}

.news-show p {
	font-weight: 300;
	text-align: justify;
	font-size: 16px;
}

.news-show img {
	margin: 0 15px 14px 0;
}

.modal.in .modal-dialog {
	width: 1000px;
	
}

.websites {

	.website {
		position: relative;
		padding-bottom: 1px;
		border-bottom: 1px solid rgba(#000, 0.2);
		margin-bottom: 20px;

		.title {
			color: $main-color;

			&:hover {
				color: rgba($main-color, 0.8);
			}
		}

		.link {
			display: block;
			font-style: italic;
			margin-bottom: 20px;
			color: #4e4e4e;

			&:hover {
				color: #4e4e4e;
			}
		}
	}
}

.modal-body {
	background-color: #000;
}

// pagination
.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
	color: $main-color;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
	background-color: $main-color;
	border-color: $main-color;
}

.pagination>li>a, .pagination>li>span {
	color: $main-color;
}

.countdown {
	text-align: center;
	background: #f5f5f5;
	padding: 30px 0;
	color: $main-color;
	box-shadow: 0 2px 2px rgba(#000, 0.2);
	border-radius: 4px;

	.part {
		display: inline-block;
	    margin-right: 20px;
	    text-align: center;
	    text-transform: uppercase;
	    font-weight: 300;
	    position: relative;;
	    font-size: 11px;

	    &::after {
	    	content: '';
		    position: absolute;
		    right: -11px;
		    top: 6px;
		    height: 24px;
		    width: 1px;
		    background: $main-color;
	    }

		&:nth-last-child(2) {
			margin-right: 0;

			&::after {
				display: none;
			}
		}

		.number {
			display: block;
		    font-size: 24px;
		    font-weight: bold;
		}
	}
}

.asian-games {
    position: relative;
    overflow: hidden;
    min-height: 180px;

    .cover {
        width: 100%;
    }

    .main {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-left: 435px;
        padding-top: 25px;
        color: rgba(0, 0, 0, 0.7);

        .title {
            font-size: 24px;
            font-weight: 200;
            margin: 10px 0 0 0;
        }

        .countdown-wrapper {
            .desc {
                font-size: 14px;
                width: 340px;
                font-weight: 500;
                float: left;
                padding-right: 40px;
                position: relative;
                margin: 0;
                margin-top: 10px;

                &::after {
                    position: absolute;
                    content: '';
                    height: 50px;
                    width: 1px;
                    background: #000;
                    right: 0;
                    top: -7px;
                }
            }
            .countdown {
                float: left;
                margin-left: 50px;

                .part {
                    float: left;
                    text-align: center;
                    margin-right: 15px;
                    text-transform: uppercase;
                    font-size: 13px;

                    .number {
                        font-size: 20px;
                        font-weight: bold;
                        display: block;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}

.mfa-container {
	max-width: 1280px;
	padding-left: 60px;
	padding-right: 60px;
	margin: auto;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: bold;
}

.main-title {
	border-left: 3px solid $main-color;
	padding-left: 15px;
	text-transform: uppercase;
	color: $main-color;
}

.bold {
	font-weight: bold;
}

.new-events {
	background: $secondary-background-color;
	padding: 15px;
	border-radius: 4px;

	.date {
		font-weight: bold;
		color: #4e4e4e;
	}

	.desc {
		a {
			color: #4e4e4e;
			font-weight: 300;

			&:hover {
				color: $main-color;
			}
		}
	}
}

.news {
	.item {
		margin-bottom: 30px;
		position: relative;
		text-align: justify;

		h5 {
			margin-bottom: 15px;
			color: $main-color;
			font-size: 15px;
		}

		.img-wrapper {
			float: left;
			width: 200px;
			height: 150px;
			overflow: hidden;
			margin-right: 20px;

			img {
				width: 100%;
			}
		}

		.read-more {
			position: absolute;
			bottom: 0;
			left: 220px;
			border-radius: 4px;
			    padding: 7px 20px 8px;
		}
	}
}

.breadcrumbs {
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			position: relative;
			float: left;
			margin-right: 15px;

			color: #4e4e4e;
			font-weight: 300;

			a {
				font-weight: bold;
				color: $main-color;
			}

			&::after {
				content: '/';
				position: absolute;
				right: -10px;
				top: 0;
			}

			&:nth-last-child(2)::after {
				display: none;
			}
		}
	}
}

.derwayys-wrapper {
	ul.derwayys {
		margin: 0;
		padding: 0;
		list-style-type: none;
		background: $secondary-background-color;
		border-radius: 4px;

		li {
			padding: 15px;

			border-bottom: 1px solid rgba(#000, 0.1);

			&:last-child {
				border: none;
			}

			a {
				color: #4e4e4e;
				font-weight: 300;

				&:hover {
					color: $main-color;
				}
			}
		}
	}
}

.something {
	position: relative;
	overflow: hidden;

           box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.18);
           border-radius: 4px;

	img {
		transform: scale(1.05) !important;
		transition: all .3s ease;
		-webkit-backface-visibility: hidden;
		-webkit-transform: translateZ(0) scale(1.0, 1.0);

	}

	&:hover {
		img {
			transform: scale(1.1) !important;
		}
	}

	.text-wrapper {
		position: absolute;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    padding-left: 15px;
	    padding-bottom: 15px;
	    padding-top: 15px;
	    //background: rgba(255,255,255,0.5);

	    .text {
	    	width: 240px;
	    	display: block;
		}
	}

	a {
		color: #4e4e4e;

		&:hover {
			color: $main-color;
		}
	}	
}

.contact-button {
	background: transparent;
    border: 1px solid #fff;
    padding: 0 25px;
    height: 40px;
    line-height: 37px;
    color: #fff;
    display: inline-block;
    border-radius: 4px;

    &:hover {
    	color: rgba(#fff, 0.8);
    	border-color: rgba(#fff, 0.8);
    }
}

.contacts-send-button {
	background: $main-color;
	color: #fff;
	padding: 7px 25px;
	border: none;
	border-radius: 4px;
}

.mfa-button {
	background: $main-color;
	color: #fff;
	padding: 7px 25px;
	border: none;
	border-radius: 4px;

	&:hover {
    	color: rgba(#fff, 0.8);
    	border-color: rgba(#fff, 0.8);
    }
}

.contacts {
	.contact-info {
		border-radius: 4px;
		padding: 15px;
		background: $secondary-background-color;
	}
}

.booklet {
	position: relative;

	img {
		float: left;
		margin-right: 30px;
	}

	.button {
			position: absolute;
	bottom: 1px;

	}
}

.asiada .desc p {
	text-align: justify;
	font-weight: 300;
}

header {
	

	min-height: 126px;
	
	&.home {
		min-height: 400px;
		background-image: url(/img/header_background.jpg);

		nav {
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
		}
	}

	&.not-home {
		nav {
			box-shadow: 0px 0px 40px rgba(#000, 0.3);
		}
	}
   
    background-position: 50% 0;
    background-size: cover;
    position: relative;

    .main {

    	.asiada-2017 {
			float: right;
		    text-align: center;
		    margin-right: 175px;
		    color: $main-color;

    		img {
	    		  width: 100px;
			    margin-bottom: 15px;
			    margin-top: 3px;
	    	}
    	}

    }

	#top-header {
		background: rgba($main-color, 0.9);
		//padding: 15px 60px 0;
		padding-top: 15px;
		height: 70px;

		.logo {
			float: left;
		    width: 43px;
		    margin-right: 15px;
		}

		.logo-text {
			float: left;
		    font-size: 15px;
		    text-transform: uppercase;
		    font-weight: bold;
		    margin-top: 11px;

		    a {
		    	color: #fff;
		    }
		    
		}

		ul.languages {
			float: right;
			padding: 0;
			margin: 0;
			list-style-type: none;
			color: #fff;
			padding-top: 9px;

			li {
				float: left;
				margin-right: 10px;
				text-transform: uppercase;

				a {
					color: #fff;
					font-weight: bold;

					&:hover,
					&.active {
						color: rgba(#fff, 0.8);
					}
				}
			}
		}
	}

	.main {
		padding: 45px 60px;

		.title {
			float: left;
			text-transform: uppercase;
			font-size: 30px;
			font-weight: bold;
			font-family: $secondary-font-family;
		}

		.logo-info {
			float: right;
			color: #fff;
			font-size: 20px;
			text-align: center;

			img {
				margin-bottom: 15px;
			}
		}
	}

	nav {
		background: rgba(#fff, 0.9);

		position: absolute;
			left: 0;
			right: 0;
			bottom: 0;

		&.fixed {
			bottom: auto;
			top: 0;
			position: fixed;
			z-index: 1000;
		}

		ul.navigation {

			list-style-type: none;
			margin: 0;
			padding: 0;
			display: table;
			margin: auto;
			height: 56px;

			> li {
				display: table-cell;
				//max-width: 180px;
				text-align: center;
				vertical-align: middle;
				height: 56px;
				position: relative;

				a {
					padding: 10px;
					font-size: 14px;
					color: #4E4E4E;
					display: block;
					width: 100%;
					height: 56px;
					text-decoration: none;
					display: table-cell;
	    			vertical-align: middle;

	    			&:hover {
	    				color: $main-color;
	    			}
				}

				&::after {
					content: '';
				    position: absolute;
				    height: 25px;
				    width: 1px;
				    background: #D2D2D2;
				    right: 0;
				    top: 16px;
				}

				&:hover {
					//background: #2c95ad;
				}

				&:nth-last-child(2)::after {
					display: none;
				}
			}
		}
	}
}



.widgets-wrapper {
	background-image: url(/img/parallax_image.jpg);
	color: #fff;
	padding-top: 50px;
	padding-bottom: 50px;

	.widgets {
		padding-left: 60px;
		padding-right: 60px;
	}

	.widget {
		text-align: center;
		width: 270px;
		margin: auto;

		img {
			height: 55px;
			margin-bottom: 30px;
			transform: scale(1);
			transition: all .3s ease;
		}

		a {
			color: #fff;

			&:hover {
				color: rgba(#fff, 0.8);
			}
		}

		.title {
			font-weight: bold;


		}

		.desc {
			font-weight: 300;
		}

		&:hover {
			img {
				transform: scale(0.9);
			}
		}
	}
}

.institute {
	padding-top: 40px;
	padding-bottom: 40px;
}

.footer-wrapper {
	background: $main-color;
}

footer {
	padding-top: 50px;
	padding-bottom: 50px;
	color: #fff;
}

.footer-links-title {
	text-transform: uppercase;
	font-weight: bold;
}

ul.footer-links {
	margin: 0;
	padding: 0;
	list-style-type: none;


	li {
		font-weight: 300;
		font-size: 14px;
		margin-bottom: 6px;

		a {
			color: #fff;
			font-weight: 300;
			font-size: 14px;

			&:hover {
				color: rgba(#fff, 0.8);
			}
		}
	}
}

.footer-bottom {
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 15px;
	padding-right: 15px;
	text-align: center;
	font-weight: 300;
	background: rgba(#000, 0.2);
	color: #fff;
	font-size: 14px;
}

@for $i from 0 through 25 {
  $step : $i*5;	
  .p-t-#{$step} {
    padding-top: ($step*1px);
  }
  .p-r-#{$step} {
    padding-right: ($step*1px);
  }
   .p-l-#{$step} {
    padding-left: ($step*1px);
  }
   .p-b-#{$step} {
    padding-bottom: ($step*1px);
  }
  .padding-#{$step} {
    padding: ($step*1px);
  }
}

@for $i from 0 through 25 {
  $step : $i*5;	
  .m-t-#{$step} {
    margin-top: ($step*1px);
  }
  .m-r-#{$step} {
    margin-right: ($step*1px);
  }
   .m-l-#{$step} {
    margin-left: ($step*1px);
  }
   .m-b-#{$step} {
    margin-bottom: ($step*1px);
  }
}

@for $i from 16 through 40 {
  .f-s-#{$i} {
    font-size: ($i*1px);
  }
}

